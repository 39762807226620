import Router from 'next/router'
import { Constants } from 'common/utils/constants'
import { Project } from 'common/project'
import { ApiTypes } from 'common/api/types/api-types'
import { setApi } from 'common/api'
import storage from './async-storage-api'
import Strings from './localisation'
import { makeStrings } from './localisation'
import { Crisp } from 'crisp-sdk-web'
import { Utils } from 'common/utils'
import flagsmith from "flagsmith/isomorphic";

export type GAEventType = 'page_view' | 'event'
export type GAEvent =
  | 'add_to_cart_ga4'
  | 'remove_from_cart_ga4'
  | 'purchase_ga4'
  | 'view_item_ga4'

export interface GA4Item {
  item_id: string
  item_name: string
  price: string
  currency: 'GBP'
  affiliation?: string
  coupon?: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  quantity?: number
  promotion_id?: string
  promotion_name?: string
}
export interface GA4Purchase {
  currency: 'GBP'
  transaction_id: string
  value: string
  items: GA4Item[]
  coupon?: string
  affiliation?: string
  tax?: string
  shipping?: string
}

export interface GA4AddToCart {
  currency: 'GBP'
  transaction_id: string
  value: string
  items: GA4Item[]
}
export type GAEventData = GA4Purchase | GA4AddToCart

function gtag(eventType: GAEventType, event?: GAEvent, data?: GAEventData) {
  if (eventType === 'page_view') {
    const payload = {
      event: 'Pageview',
      pagePath: document.location.href,
      pageTitle: document.title,
      visitorType: 'customer',
    }
    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  } else {
    const payload = {
      ecommerce_ga4: data,
      event,
    }

    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  }
}
interface WebAPI
  extends ApiTypes<
    any,
    {
      removeItem: (key: string, req?: any) => undefined | unknown
      getItemSync: (key: string, req?: any) => string
      setItemSync: (
        key: string,
        value: string,
        req?: any,
      ) => undefined | unknown
      removeItemSync: (key: string, req?: any) => undefined | unknown
    }
  > {
  getStoredLocale: (requestedLocale?: string) => string
  setStoredLocale: (requestedLocale: string) => void
  logoutRedirect: () => void
}
let token = ''
const API: WebAPI = {
  confirmEmailRedirect(email) {
    Router.replace(`/auth/confirm-email?email=${email}`)
  },
  getAPIBaseUrl: () => process.env.NEXT_PUBLIC_API_URL || '',
  getStoredLocale: (requestedLocale?: string) => {
    return (
      API.storage.getItemSync('NEXT_LOCALE') ||
      requestedLocale ||
      Constants.defaultLocale
    )
  },
  getToken: () => {
    if (document.location.pathname === '/verification') {
      return Utils.fromParam().t
    }
    return null
  },
  identify(id) {
    // @ts-ignore
    if (Project.mixpanel && typeof mixpanel !== 'undefined') {
      // @ts-ignore
      mixpanel.identify(id)
    }
  },
  isMobile: () => false,
  // @ts-ignore
  log(namespace: keyof (typeof Project)['logs'], ...args: any[]) {
    if (Project.logs[namespace]) {
      // eslint-disable-next-line no-console
      console.log.apply(this, [namespace, ...args])
    }
  },

  loginRedirect(user, defaultSubscriptionId) {
    const params = Router.query
    params.redirect = params.redirect || '/'
    // @ts-ignore
    Router.replace(params.redirect, params.as || params.redirect, {
      shallow: true,
    })
  },

  logout() {
    // cookies.remove('token')
    Router.replace('/')
  },

  logoutRedirect() {
    if (Router.query.t) {
      return
    }
    if (document.location.pathname === '/') {
      return
    }
    const path = `/?redirect=${encodeURIComponent(document.location.pathname)}`
    Router.replace(path)
  },

  makeStrings,

  middlewares: [],

  setProperties(data: Record<any, any>) {
    if (data) {
      if (data.name) {
        Crisp.user.setNickname(data.name)
      }
      if (data.user_id) {
        Crisp.user.setEmail(data.user_id)
      }
      Crisp.session.setData(data)
    }
  },

  getFlagsmith:()=>flagsmith,

  setStoredLocale: (locale: string) => {
    Strings.setLanguage(locale)
    API.storage.setItemSync('NEXT_LOCALE', locale)
    // document.location = document.location
    // @ts-ignore
    const { asPath, pathname, query } = Router.router?.state || {}
    // change just the locale and maintain all other route information including href's query
    // @ts-ignore
    Router.router?.push({ pathname, query }, asPath, { locale })
  },

  setToken: (t: string) => {
    token = t
  },

  storage,
  //@ts-ignore
  trackEvent(event: GAEvent, data: GAEventData) {
    if (typeof dataLayer !== 'undefined') {
      gtag('event', event, data)
    }
  },
  trackPage() {
    if (typeof dataLayer !== 'undefined') {
      gtag('page_view')
    }
  },
}
setApi(API)
export { API }
